(function ($, exports) {
  /* 
  Set Language toggle defaults on landing. 
  Eg. Getting and setting cookies, redirecting, etc.
  */

  /*
  Check the DOM for language menu overrides set from the CMS.
  If any one language menu has an override, use it as proxy for all language menus.
  First language menu in the DOM takes precedence.
  */
  const DATA_LANG_SELECTOR = '[data-lang]';
  const DATA_DEFAULT_LANGUAGE_SELECTOR = '[data-default-language]';

  const $dataLangs = $(DATA_LANG_SELECTOR) || [];
  const languageArray = $dataLangs.map(function () {
    return $(this).data('lang');
  }).get();
  const $languageSettings = $(DATA_DEFAULT_LANGUAGE_SELECTOR) || {};
  const languageDefault = $languageSettings?.data()?.defaultLanguage ?? 'en';
  const localeCode = $languageSettings?.data()?.localeCode ?? '';
  const forceLanguageSwitch = $languageSettings?.data()?.forceLanguageSwitch ?? true;
  const $localeCookie = $.cookie('LOCALE');
  const localeCookieLanguage = $localeCookie?.split('_')[0];
  const urlParam = window.location.search;
  const path = window.location.pathname;
  const pathArray = path.split('/');
  const isAccountUrl = path.includes('/account') === true;
  const isCheckoutUrl = path.includes('/checkout') === true;
  const isSearchUrl = path.includes('/esearch') === true;
  const isUrlLanguage = urlParam.includes('?LOCALE=') === true;

  /* 
  Set the active language on landing.
  Keep default language active unless the url path has a different language code.  
  */
  const activeLanguage = languageArray.includes(pathArray[1]) ?
    pathArray[1] :
    languageDefault;

  /* 
  EXPORT: switch language from whatever UI implementation (eg. dropdown, button, etc.)
  */
  function switchLanguage(language) {
    let newPath;
    const pathSubstring = path.substring(1);

    if (!language) {
      return;
    }

    $(languageArray).each(function (i, lang) {
      newPath = pathSubstring.replace(lang + '/', '');
    });

    const languagePath =
      language === languageDefault
        ? newPath === pathSubstring
          ? ''
          : newPath
        : language + '/' + newPath;

    window.location.href = '/' + languagePath;
  }

  /*
  EXPORT: set the cookie for the active language
  */
  function setCookie(language) {
    var cookieValue = language + '_' + localeCode;

    if (!localeCode) {
      return;
    }

    $.cookie('LOCALE', cookieValue, {
      path: '/'
    });
  }

  function getActiveLanguage() {
    return activeLanguage;
  }

  /*
  On landing, reload if locale cookie does not match the active language.
  */
  if (
    $localeCookie !== null &&
    forceLanguageSwitch === true &&
    localeCookieLanguage !== activeLanguage &&
    isAccountUrl !== true &&
    isCheckoutUrl !== true &&
    isSearchUrl !== true &&
    isUrlLanguage !== true
  ) {
    if (languageArray.includes(localeCookieLanguage)) {
      setCookie(localeCookieLanguage);
      switchLanguage(localeCookieLanguage);
    }
  }

  /*
  On landing, reload if url params has the locale
  */
  if (isUrlLanguage === true) {
    const urlLanguage = urlParam.split('=')[1];
    const language = urlLanguage.split('_')[0];

    setCookie(language);
    switchLanguage(language);
  }

  exports.languageToggle = {
    switchLanguage,
    setCookie,
    getActiveLanguage
  };
})(jQuery, typeof exports === 'object' ? exports : window);
